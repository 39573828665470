<template>
  <b-carousel
    id="carousel-1"
    class="custom-carousel"
    :interval="4000"
    controls
    indicators
    background="black"
    style="text-shadow: 1px 1px 2px #333; height: 350px;"
  >
    <b-carousel-slide
      v-for="(image, key) in images"
      :key="key"
    >
      <template v-slot:img>
        <b-img
          style="max-width: 100%; max-height: 350px;"
          center
          :src="image.image"
          alt="flealover" />
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>

  export default {
    name: 'ImageSlider',

    data () {
      return {
      }
    },

    props: {
      images: {
        type: Array,
        required: true,
      }
    }
  }
</script>

<style>

</style>
