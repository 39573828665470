<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-img width="150" height="130" :src="require('@base/assets/images/sitelogo.png')" />
      </div>
      <b-row v-if="anonMarket.visualName" class="justify-content-center">
        <h4 class="w-100 text-center bg-dark text-white p-2 rounded">{{ anonMarket.visualName }}</h4>
      </b-row>
      <b-row class="mt-3">
        <b-col
          v-if="anonMarket && anonMarket.marketImages.length"
          class="mt-2 no-padding-left no-padding-right custom-container-md"
          cols="12" md="12" sm="12" lg="9"
        >
          <image-slider
            :images="anonMarket.marketImages"
          />
        </b-col>
        <b-col
          class="mt-2 text-center no-padding-right no-padding-left align-self-center"
          cols="12" md="12" sm="12" lg="3"
        >
          <h1>{{ $t('pages.login.user.download_app_title') }}</h1>
          <a
            :href="appStoreUrl"
            target="_blank"
          >
            <b-img 
              width="200" 
              height="100" 
              :src="require(`@base/assets/images/badges/download-on-the-app-store-${$_languageCode}.svg`)"
            />
          </a>
          <a
            :href="playMarketUrl"
            target="_blank"
          >
            <b-img
              width="230"
              height="100"
              :src="require(`@base/assets/images/badges/google-play-badge-${$_languageCode}.png`)"
            />
          </a>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-card-group deck class="mt-2">
          <b-col
            class="no-padding-right"
            cols="12" md="12" sm="12" lg="7"
            v-if="anonMarket.description || anonMarket.extraMarketInfo"
          >
            <market-description-card
              :market="anonMarket"
            />
          </b-col>
          <b-col
            class="no-padding-right"
          >
            <market-contact-info-card
              :market="anonMarket"
              :market-feedback="marketFeedback"
            />
            <market-opening-hours-card
              :market="anonMarket"
            />
          </b-col>
        </b-card-group>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import ImageSlider from '@base/components/ImageSlider.vue'
  import MarketDescriptionCard from '@base/components/MarketDescriptionCard.vue'
  import MarketContactInfoCard from '@base/components/MarketContactInfoCard.vue'
  import MarketOpeningHoursCard from '@base/components/MarketOpeningHoursCard.vue'
  import { mapState } from 'vuex'
  import { anonRequestInterceptor } from '@base/store'
  import { marketFeedback } from '@base/services'
  import { APP_STORE_URL, PLAY_MARKET_URL } from '@base/configs'


  marketFeedback.interceptors.request.use(anonRequestInterceptor)

  export default {
    name: 'AnonUserView',

    components: {
      ImageSlider,
      MarketDescriptionCard,
      MarketContactInfoCard,
      MarketOpeningHoursCard,
    },

    data () {
      return {
        marketFeedback: marketFeedback,
        appStoreUrl: APP_STORE_URL,
        playMarketUrl: PLAY_MARKET_URL,
      }
    },

    created() {
      window.addEventListener('resize', this.handleResize)
    },

    computed: {
      ...mapState(['anonMarket']),
    },

    mounted() {
      this.setCardFooterStyle()
    },

    methods: {
      setCardFooterStyle () {

        // Define max height of all card footers
        const cardFooterClass = document.getElementsByClassName('card-footer')
        const customCardFooterClass = document.getElementsByClassName('custom-card-footer')
        let maxFooterHeight = 0
        for (const elem of customCardFooterClass) {
          if (elem.offsetHeight > maxFooterHeight) {
            maxFooterHeight = elem.offsetHeight
          }
        }

        // Set max height to every footer of card to make it symmetrical
        // and center footer content
        for (const elem of cardFooterClass) {
          elem.style.height = `calc(${maxFooterHeight}px + 1rem)`
          elem.style.display = 'flex'
          elem.style.justifyContent = 'center'
          elem.style.alignItems = 'center'
        }
      },

      handleResize () {
        this.setCardFooterStyle()
      },
    }
  }
</script>

<style>

</style>
