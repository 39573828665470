import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@anonymous/store'
import { actionTypes } from '@base/store/store-types'
import { getToken } from '@base/store'
import {
  ADMIN_SECTION_PATH,
  DJANGO_ADMIN_PATH_NAME,
  // mobileAppScheme,
  // useMobileAppScheme,
  USER_SECTION_PATH,
} from '@base/configs'

import NotFoundPage from '@base/components/NotFoundPage.vue'
import BasePage from '@anonymous/views/BasePage.vue'
import AdminLogin from '@anonymous/components/admin/AdminLogin.vue'
import AdminResetPassword
  from '@anonymous/components/admin/AdminResetPassword.vue'
import AdminConfirmResetPassword
  from '@anonymous/components/admin/AdminConfirmResetPassword.vue'
import AdminEmailConfirm
  from '@anonymous/components/admin/AdminEmailConfirm.vue'
import AnonUserView from '@anonymous/views/AnonUserView.vue'
import CreateMarket from '@anonymous/components/CreateMarket.vue'

import VueRouterMiddleware, {
  createMiddleware,
  middleware
} from 'vue-router-middleware'
import InstagramMediaView from '@base/views/InstagramMediaView'


// Set market_id header to axios from local storage
const checkTokenStorage = (next, tokenName) => {
  const token = getToken(tokenName)
  if (token && token.isAdmin)
    window.location.replace(ADMIN_SECTION_PATH)
  else if (token && !token.isAdmin)
    window.location.replace(USER_SECTION_PATH)
  else
    next()
}

// const checkDeepLink = (to) => {
//   // If user opens web app via link from email (or by another way of deep link)
//   // then we check if he does this from mobile - redirect him to mobile app
//   if (useMobileAppScheme && (navigator.userAgent.toLowerCase().indexOf('android') > -1
//       || navigator.userAgent.toLowerCase().indexOf('iphone') > -1)) {
//     window.location.href = `${mobileAppScheme}${to.path}${Object.values(to.query).join('/')}`
//   }
// }

createMiddleware('check-media-id', (args, to, from, next) => {
  store.dispatch(actionTypes.GET_MARKET_INSTAGRAM_MEDIA, to.params.mediaId)
    .then(() => {
      if (store.state.marketInstagramMedia) {
        if (!store.state.marketInstagramMedia.mediaId)
          next({ name: 'not-found' })
        else
          next()
      }
      else
        next({ name: 'not-found' })
    })
    .catch(() => {
      next({ name: 'not-found' })
    })
})

createMiddleware('check-market-id', (args, to, from, next) => {
  store.dispatch(actionTypes.GET_ANON_MARKET, to.params.marketId)
    .then(() => {
      if (store.state.anonMarket) {
        if (!store.state.anonMarket.marketId)
          next({ name: 'not-found' })
        else
          next()
      }
      else
        next({ name: 'not-found' })
    })
    .catch(() => {
      next({ name: 'not-found' })
    })
})

const routes = [
  {
    path: '/404',
    name: 'not-found',
    component: NotFoundPage,
  },
  {
    path: '/',
    component: BasePage,
    children: [
      {
        path: '',
        name: 'base',
        beforeEnter: (to, from, next) => {
          checkTokenStorage(next, 'AdminToken')
        },
        component: AdminLogin,
      },
      {
        path: DJANGO_ADMIN_PATH_NAME,
        beforeEnter:() => {
          location.replace('/' + DJANGO_ADMIN_PATH_NAME)
        }
      },
      {
        path: 'reset_password',
        name: 'admin-reset-password',
        component: AdminResetPassword,
      },
      {
        path: 'confirm_email',
        beforeEnter: (to, from, next) => {
          checkTokenStorage(next, 'AdminToken')
        },
        name: 'admin-confirm-email',
        component: AdminEmailConfirm,
      },
      {
        path: 'confirm_reset_password',
        beforeEnter: (to, from, next) => {
          checkTokenStorage(next, 'AdminToken')
        },
        name: 'admin-confirm-reset-password',
        component: AdminConfirmResetPassword,
      },
      {
        path: 'register_market',
        name: 'register-market',
        component: CreateMarket,
      },
      ...middleware('check-market-id', [
        {
          path: 'market/flealover/:marketId',
          name: 'market-page',
          component: AnonUserView,
        },
      ]),
      ...middleware('check-media-id', [
        {
          path: 'post/flealover/:mediaId',
          name: 'media-page',
          component: InstagramMediaView,
        },
      ]),
    ]
  },
  {
    path: '*',
    redirect: { name: 'not-found' }
  }
]

const router = new VueRouter({ mode: 'history', routes })

Vue.use(VueRouter)
Vue.use(VueRouterMiddleware, { router })

export default router
