<template>
  <div>
    <b-container>
      <div class="d-flex justify-content-center">
        <b-img width="150" height="130" :src="require('@base/assets/images/sitelogo.png')" />
      </div>
      <b-row class="justify-content-center"><h1>{{ $t('title.auth.reset_password') }}</h1></b-row>
      <b-row class="justify-content-center">
        <div v-if="resetPasswordData.validLink && showForm" class="w-50">
          <b-form @submit.prevent="confirmResetPassword">
            <b-form-group
              id="password1-group"
              :label="$t('auth.reset_new_password')"
              label-for="password1"
              :invalid-feedback="invalidNewPassword1"
              :state="stateNewPassword1"
            >
              <b-form-input
                id="password1"
                v-model="newPassword1"
                type="password"
                :state="stateNewPassword1Input"
                :placeholder="$t('auth.placeholder_password')"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="password2-group"
              :label="$t('auth.reset_confirm_password')"
              label-for="password2"
              :invalid-feedback="invalidNewPassword2"
              :state="stateNewPassword2"
            >
              <b-form-input
                id="password2"
                v-model="newPassword2"
                type="password"
                :state="stateNewPassword2Input"
                :placeholder="$t('auth.placeholder_password')"
              ></b-form-input>
            </b-form-group>
            <vue-recaptcha
              v-if="useRecaptcha"
              ref="recaptcha"
              @expired="onCaptchaExpired"
              @verify="onCaptchaVerified"
              @error="onRecaptchaError"
              :sitekey="recaptchaSiteKey"
              size="invisible" />
            <div class="form-actions">
              <b-button
                type="submit"
                :disabled="loading"
                variant="primary">
                {{ $t('auth.send_password_button') }}
              </b-button>
            </div>
          </b-form>
        </div>
        <div v-else-if="!showForm && resetPasswordData.validLink">
          <h3>
            {{ $t('auth.reset_password_confirmed', { countDown: countDown }) }}
          </h3>
        </div>
        <div v-else>
          <div class="text-center text-secondary">
            <h3>{{ $t('auth.reset_invalid_link') }}</h3>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import VueRecaptcha from 'vue-recaptcha'
  import countDownTimerMixin from '@base/mixins/CountDownTimerMixin.vue'
  import { REDIRECT_COUNTDOWN_SECONDS, RECAPTCHA_SITE_KEY, USE_RECAPTCHA } from '@base/configs'
  import { required, sameAs } from 'vuelidate/lib/validators'

  export default {
    name: 'AdminConfirmResetPassword',

    components: { VueRecaptcha },

    mixins: [ countDownTimerMixin ],

    data () {
      return {
        newPassword1: null,
        newPassword2: null,
        showForm: true,
        countDown: REDIRECT_COUNTDOWN_SECONDS,
        useRecaptcha: USE_RECAPTCHA,
        recaptchaToken: null,
        recaptchaSiteKey: RECAPTCHA_SITE_KEY,
        recaptchaError: false,
        loading: false
      }
    },

    created () {
      this.$store.dispatch(this.$_actionTypes.GET_RESET_PASSWORD_DATA, {...this.$route.query})
    },

    validations: {
      newPassword1: {
        required,
      },
      newPassword2: {
        required,
        sameAs: sameAs('newPassword1')
      }
    },

    computed: {
      ...mapState(['resetPasswordData']),
      payloads () {
        return {
          newPassword1: this.newPassword1,
          newPassword2: this.newPassword2,
          uid: this.resetPasswordData.uid,
          token: this.resetPasswordData.token,
          grecaptchaToken: this.useRecaptcha ? this.recaptchaToken: undefined,
        }
      },
      stateNewPassword1Input () {
        return (this.$v.newPassword1.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword1 () {
        return (!this.$v.newPassword1.$error)
      },
      invalidNewPassword1 () {
        if (this.$v.newPassword1.$error) {
          if (!this.$v.newPassword1.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
        }
        return null
      },
      stateNewPassword2Input () {
        return (this.$v.newPassword2.$error || !this.$v.newPassword2.sameAs) ? false : null
      },
      stateNewPassword2 () {
        return (!this.$v.newPassword2.$error)
      },
      invalidNewPassword2 () {
        if (this.$v.newPassword2.$error) {
          if (!this.$v.newPassword2.required)
            return this.$t('validation.required', { field: this.$t('auth.field.password') })
          else if (!this.$v.newPassword2.sameAs) {
            return this.$t('validation.same_as', {field: this.$t('auth.field.password')})
          }
        }
        return null
      },
    },

    methods: {
      onRecaptchaError () {
        this.recaptchaError = true
      },

      onCaptchaExpired () {
        if (this.useRecaptcha)
          this.$refs.recaptcha.reset()
      },

      confirmResetPassword () {
        this.$v.$touch()
        if (this.useRecaptcha) {
          if (this.recaptchaError) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: this.$t('notifications.title.error'),
              text: this.$t('error.recaptcha')
            })
            return
          }
        }
        if (!this.$v.$invalid) {
          if (this.useRecaptcha)
            this.$refs.recaptcha.execute()
          else
            this.onCaptchaVerified()
        }
      },

      onCaptchaVerified (recaptchaToken) {
        this.$v.$reset()
        if (this.useRecaptcha) {
          this.recaptchaToken = recaptchaToken
          this.$refs.recaptcha.reset()
        }
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CONFIRM_RESET_PASSWORD, this.payloads)
          .then((response) => {
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: response.detail
            })
            this.showForm = false
            this.countDownTimer(this.countDown, this, { name: 'base' })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
