<template>
  <div>
    <b-container>
      <b-row v-if="showForm" class="justify-content-center mt-5"><h1>{{ $t('pages.create_market.title') }}</h1></b-row>
      <b-row v-if="showForm" class="justify-content-center"><h6 class="text-center">{{ $t('pages.create_market.subtitle') }}</h6></b-row>
      <b-col v-if="showForm" cols="12" lg="6" offset-lg="3" class="no-padding-left no-padding-right">
        <b-form @submit.prevent="createMarket">

          <!--STORE NAME-->
          <b-form-group
            id="store-name-group"
            :label="$t('form.market.store_name_label')"
            label-for="store-name"
            :invalid-feedback="invalidVisualName"
            :state="stateVisualName"
          >
            <b-form-input
              id="store-name"
              v-model="marketForm.visualName"
              type="text"
              :state="stateVisualNameInput"
              :placeholder="$t('form.market.store_name_field')"
            ></b-form-input>
          </b-form-group>
          
          <!--EMAIL-->
          <b-form-group
            id="email-group"
            :label="$t('form.market.email')"
            :invalid-feedback="invalidEmail"
            :state="stateEmail"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="marketForm.email"
              :state="stateEmailInput"
              :placeholder="$t('form.market.email')"
            ></b-form-input>
          </b-form-group>

          <!--PHONE-->
          <b-form-group
            id="phone-group"
            :label="$t('form.market.phone')"
            label-for="phone"
            :invalid-feedback="invalidPhone"
            :state="statePhone"
          >
            <vue-phone-number-input
              id="phone"
              @update="updatePhone"
              v-model="marketForm.phone"
              :error="!isPhoneValid || $v.marketForm.phone.$error"
              :default-country-code="phoneDefaultCountryCode"/>
          </b-form-group>

          <!--GOOGLE MAPS ADDRESS-->
          <b-form-group
            id="map-group"
            :label="$t('form.market.address')"
            label-for="map"
            :invalid-feedback="invalidAddress"
            :state="stateAddress"
          >
            <vue-google-autocomplete
              id="map"
              ref="googleMapsInput"
              :classname="`form-control ${stateAddress ? 'is-invalid' : ''}`"
              :placeholder="$t('form.market.address')"
              :fields="['address_components', 'formatted_address', 'geometry', 'url', 'utc_offset_minutes']"
              @inputChange="onGoogleInputChange"
              @keypress.enter="onGoogleSubmit"
              @no-results-found="addressNoResults"
              @placechanged="getAddressData"
            >
            </vue-google-autocomplete>
            <b-form-text
              v-if="marketForm.addressUrl && !addressErrorName"
            >
              <a
                target="blank"
                :href="marketForm.addressUrl"
              >{{ $t('form.market.help_text.address') }}</a>
            </b-form-text>
          </b-form-group>

          <vue-recaptcha
            v-if="useRecaptcha"
            ref="recaptcha"
            @expired="onCaptchaExpired"
            @verify="onCaptchaVerified"
            @error="onRecaptchaError"
            :sitekey="recaptchaSiteKey"
            size="invisible" />
          <div class="form-actions">
            <b-button
              :disabled="loading"
              type="submit"
              variant="primary">
              {{ $t('form.market.button_create_market') }}
            </b-button>
          </div>
        </b-form>
      </b-col>
      <b-col class="mt-5" v-else>
        <h3 class="text-center mr-5 ml-5">
          {{ $t('notifications.text.create_market.success_market_created_1') }}
          <br><br>
          {{ $t('notifications.text.create_market.success_market_created_2') }}
        </h3>
        <div class="d-flex justify-content-center mt-3">
          <b-button
            @click="$bvModal.show('feedback-form')"
            variant="primary">
            {{ $t('pages.feedback_form.button', { company_name : 'Flea Lover'}) }}
          </b-button>
        </div>
      </b-col>
    </b-container>
    <feedback-modal
      modalId="feedback-form"
      @formSubmit="sendFeedback"
    />
  </div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha'
  import {
    RECAPTCHA_SITE_KEY,
    USE_RECAPTCHA,
    webConfig,
    PHONE_DEFAULT_COUNTRY_CODE
  } from '@base/configs'
  import { required, email } from 'vuelidate/lib/validators'
  import FeedbackModal from '@base/components/FeedbackModal.vue'

  export const defaultMarketForm = {
    visualName: '',
    email: '',
    phone: '',
    address: '',
    addressUrl: '',
    geolocation: '',
  }

  export default {
    name: 'CreateMarket',

    components: { VueRecaptcha, FeedbackModal },

    data () {
      return {
        marketForm: { ...defaultMarketForm },
        addressErrorName: '',
        domain: webConfig.baseURL,
        phoneNumberData: null,
        useRecaptcha: USE_RECAPTCHA,
        recaptchaToken: null,
        recaptchaSiteKey: RECAPTCHA_SITE_KEY,
        recaptchaError: false,
        loading: false,
        showForm: true,
      }
    },

    computed: {
      payloads () {
        return {
          visualName: this.marketForm.visualName,
          email: this.marketForm.email,
          phone: this.phoneNumberData ? this.phoneNumberData.formattedNumber : '',
          address: this.marketForm.address,
          addressUrl: this.marketForm.addressUrl,
          geolocation: this.marketForm.geolocation,
        }
      },

      phoneDefaultCountryCode () {
        // return this.marketSettingsForm.supportCountry ? this.marketSettingsForm.supportCountry.code : PHONE_DEFAULT_COUNTRY_CODE
        return PHONE_DEFAULT_COUNTRY_CODE
      },

      // VALIDATIONS
      isPhoneValid () {
        if (!this.phoneNumberData)
          return true
        return this.phoneNumberData && this.marketForm.phone && this.phoneNumberData.isValid
      },
      stateVisualNameInput () {
        return this.$v.marketForm.visualName.$error ? false : null
      },
      stateVisualName () {
        return (!this.$v.marketForm.visualName.$error)
      },
      invalidVisualName () {
        if (this.$v.marketForm.visualName.$error) {
          if (!this.$v.marketForm.visualName.required)
            return this.$t('validation.required', { field: this.$t('form.market.store_name_field') })
        }
        return null
      },
      stateEmailInput () {
        return this.$v.marketForm.email.$error ? false : null
      },
      stateEmail () {
        return (!this.$v.marketForm.email.$error)
      },
      invalidEmail () {
        if (this.$v.marketForm.email.$error) {
          if (!this.$v.marketForm.email.required)
            return this.$t('validation.required', { field: this.$t('form.market.email') })
          else if (!this.$v.marketForm.email.email)
            return this.$t('validation.email')
        }
        return null
      },
      statePhone () {
        return (!this.isPhoneValid || this.$v.marketForm.phone.$error) ? false : null
      },
      invalidPhone () {
        if (this.$v.marketForm.phone.$error) {
          if (!this.$v.marketForm.phone.required)
            return this.$t('validation.required', { field: this.$t('form.market.phone') })
        }
        return null
      },
      stateAddress () {
        return (!!this.addressErrorName || this.$v.marketForm.address.$error)
      },
      invalidAddress () {
        if (this.addressErrorName) {
          return this.$t('validation.google_maps_address', { name: this.addressErrorName })
        } else if (this.$v.marketForm.address.$error) {
          if (!this.$v.marketForm.address.required)
            return this.$t('validation.required', {field: this.$t('form.market.address')})
        }
        return null
      },
    },

    validations: {
      phoneNumberData: {
        required
      },
      marketForm: {
        visualName: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required
        },
        address: {
          required,
        }
      }
    },

    methods: {
      updatePhone (phoneObj) {
        this.phoneNumberData = phoneObj
      },

      /**
      * When the location found
      * @param {Object} addressData Data of the found location
      * @param {Object} placeResultData PlaceResult object
      */
      getAddressData (addressData, placeResultData) {
        this.resetAddressError()
        // Prevent blur after choosing address
        this.$refs.googleMapsInput.focus()
        this.parseGoogleAddress(placeResultData, addressData)
      },

      parseGoogleAddress (placeResultData, addressData) {
        this.marketForm.address = placeResultData.formatted_address
        this.marketForm.addressUrl = placeResultData.url
        this.marketForm.geolocation = `${addressData.latitude},${addressData.longitude}`
      },

      addressNoResults (obj) {
        this.addressErrorName = obj.name
      },
      resetAddressError () {
        this.addressErrorName = ''
      },
      onGoogleInputChange (obj) {
        if (!obj.newVal) {
          this.resetAddressError()
          const resetFields = [
            'address',
            'addressUrl',
            'geolocation',
          ]
          for (const field of resetFields)
            this.marketForm[field] = ''
        }
      },

      onGoogleSubmit (e) {
        if (!this.marketForm.address)
          e.preventDefault()
        else
          return true
      },

      onRecaptchaError () {
        this.recaptchaError = true
      },

      onCaptchaExpired () {
        if (this.useRecaptcha)
          this.$refs.recaptcha.reset()
      },

      createMarket () {
        this.$v.$touch()
        if (this.$v.$invalid || !this.isPhoneValid
          || this.addressErrorName)
          return
        if (this.useRecaptcha) {
          if (this.recaptchaError) {
            this.$notify({
              group: 'app',
              type: 'error',
              title: this.$t('notifications.title.error'),
              text: this.$t('error.recaptcha')
            })
            return
          }
        }
        if (this.useRecaptcha)
          this.$refs.recaptcha.execute()
        else
          this.onCaptchaVerified()
      },

      onCaptchaVerified (recaptchaToken) {
        this.$v.$reset()
        if (this.useRecaptcha) {
          this.recaptchaToken = recaptchaToken
          this.$refs.recaptcha.reset()
        }
        this.loading = true
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.CREATE_MARKET, this.payloads)
          .then(() => {
            this.showForm = false
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loading = false
            this.loader.hide()
          })
      },

      sendFeedback (payloads) {
        this.loader = this.$loading.show(this.$_loaderOptions)
        this.$store.dispatch(this.$_actionTypes.SEND_FEEDBACK, payloads)
          .then(() => {
            this.$nextTick(() => {
              this.$bvModal.hide('feedback-form')
            })
            this.$notify({
              group: 'app',
              type: 'success',
              title: this.$t('notifications.title.success'),
              text: this.$t('notifications.text.feedback.success_feedback_sent')
            })
          })
          .catch((error) => {
            this.$_notifyError(error, this)
          })
          .finally(() => {
            this.loader.hide()
          })
      }
    }
  }
</script>

<style>

</style>
